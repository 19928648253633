import { computed } from 'vue'

import type { DataItem } from '@/models/dataItem.type'
import { i18nPlugin } from '@/plugins/i18n.plugin'

export enum Permission {
  // Absence code
  ABSENCE_CODE_CREATE = 'absence_code.create',
  ABSENCE_CODE_DELETE = 'absence_code.delete',

  ABSENCE_CODE_READ = 'absence_code.read',
  ABSENCE_CODE_UPDATE = 'absence_code.update',
  // Accidents
  ACCIDENT_CREATE = 'accident.create',
  ACCIDENT_DELETE = 'accident.delete',
  ACCIDENT_DELETE_CONCEPT = 'accident.delete_concept',

  // ADMIN_READ = 'admin.read',
  // ADMIN_WRITE = 'admin.write',

  // // Availabilities
  // AVAILABILITY_CREATE = 'availability.create',
  // AVAILABILITY_READ = 'availability.read',
  // AVAILABILITY_UPDATE = 'availability.update',
  // AVAILABILITY_DELETE = 'availability.delete',

  ACCIDENT_READ = 'accident.read',
  ACCIDENT_UPDATE = 'accident.update',
  ACCIDENT_UPDATE_CONCEPT = 'accident.update_concept',
  ACCIDENT_UPDATE_STATUS = 'accident.update_status',
  ADMIN = 'admin',

  // Advance Amount
  ADVANCE_AMOUNT_CREATE = 'advance_amount.create',
  ADVANCE_AMOUNT_DELETE = 'advance_amount.delete',
  ADVANCE_AMOUNT_READ = 'advance_amount.read',
  ADVANCE_AMOUNT_UPDATE = 'advance_amount.update',
  // Anomalies
  ANOMALY_READ = 'anomaly.read',

  ANOMALY_RESOLVE = 'anomaly.resolve',

  ANOMALY_VALIDATE = 'anomaly.validate',
  // Barema
  BAREMA_CREATE = 'barema.create',
  BAREMA_DELETE = 'barema.delete',
  BAREMA_READ = 'barema.read',

  BAREMA_UPDATE = 'barema.update',

  // Benefit code
  BENEFIT_CODE_CREATE = 'benefit_code.create',

  BENEFIT_CODE_DELETE = 'benefit_code.delete',
  BENEFIT_CODE_READ = 'benefit_code.read',
  BENEFIT_CODE_UPDATE = 'benefit_code.update',

  // Blog
  BLOG_CREATE = 'blog.create',
  BLOG_DELETE = 'blog.delete',
  BLOG_READ = 'blog.read',
  BLOG_UPDATE = 'blog.update',

  // Chat
  CHAT_READ = 'chat.read',

  CHAT_WRITE = 'chat.write',
  // Cheques
  CHEQUE_CREATE = 'cheque.create',
  CHEQUE_DELETE = 'cheque.delete',
  CHEQUE_READ = 'cheque.read',

  CHEQUE_SETTINGS = 'cheque.settings',
  CHEQUE_UPDATE = 'cheque.update',
  // Claims
  CLAIM_CREATE = 'claim.create',
  CLAIM_DELETE = 'claim.delete',

  // Performances
  // PERFORMANCE_READ = 'performance.read',
  // PERFORMANCE_UPDATE = 'performance.update',
  CLAIM_READ = 'claim.read',
  CLAIM_UPDATE = 'claim.update',

  // Customer
  CUSTOMER_CREATE = 'customer.create',
  CUSTOMER_DELETE = 'customer.delete',
  CUSTOMER_READ = 'customer.read',
  // Customer Reference
  CUSTOMER_REFERENCE_TAKEOVER = 'customer.reference_takeover',
  // Customer set custom invoice price
  CUSTOMER_SET_CUSTOM_INVOICE_PRICE = 'customer.set_custom_invoice_price',
  CUSTOMER_UPDATE = 'customer.update',

  DEBTOR_CLOSE = 'debtor.close',
  // Debtors
  DEBTOR_READ = 'debtor.read',
  DEBTOR_UPDATE = 'debtor.update',
  DEPARTMENT_CREATE = 'department.create',

  DEPARTMENT_DELETE = 'department.delete',
  DEPARTMENT_READ = 'department.read',
  // Departments
  DEPARTMENT_READ_ALL = 'department.read_all',
  DEPARTMENT_UPDATE = 'department.update',

  DIMONA_DECLARATION_CREATE = 'dimona_declaration.create',

  DIMONA_DECLARATION_DELETE = 'dimona_declaration.delete',
  DIMONA_DECLARATION_READ = 'dimona_declaration.read',

  DIMONA_LOGS_READ = 'dimona_logs.read',
  // Dimona
  DIMONA_RETRY_UPLOAD = 'dimona.retry_upload',
  // District
  DISTRICT_CREATE = 'district.create',

  DISTRICT_DELETE = 'district.delete',
  DISTRICT_READ = 'district.read',
  DISTRICT_UPDATE = 'district.update',
  ECONOMIC_UNEMPLOYMENT_DECLARATIONS_DELETE = 'economic_unemployment_declarations.delete',
  ECONOMIC_UNEMPLOYMENT_DECLARATIONS_READ = 'economic_unemployment_declarations.read',
  ECONOMIC_UNEMPLOYMENT_DECLARATIONS_UPDATE = 'economic_unemployment_declarations.update',
  ECONOMIC_UNEMPLOYMENT_DECLARATIONS_WRITE = 'economic_unemployment_declarations.write',

  // Economic Unemployment Periods
  ECONOMIC_UNEMPLOYMENT_PERIODS_CREATE = 'economic_unemployment_periods.create',
  ECONOMIC_UNEMPLOYMENT_PERIODS_DELETE = 'economic_unemployment_periods.delete',

  ECONOMIC_UNEMPLOYMENT_PERIODS_READ = 'economic_unemployment_periods.read',
  ECONOMIC_UNEMPLOYMENT_PERIODS_UPDATE = 'economic_unemployment_periods.update',
  ECONOMIC_UNEMPLOYMENT_VALIDATION_BOOK_DELETE = 'economic_unemployment_validation_book.delete',
  ECONOMIC_UNEMPLOYMENT_VALIDATION_BOOK_READ = 'economic_unemployment_validation_book.read',

  ECONOMIC_UNEMPLOYMENT_VALIDATION_BOOK_UPDATE = 'economic_unemployment_validation_book.update',

  ECONOMIC_UNEMPLOYMENT_VALIDATION_BOOK_WRITE = 'economic_unemployment_validation_book.write',
  // EMPLOYEE ABSENCE COUNTER
  EMPLOYEE_ABSENCE_COUNTER_CREATE = 'employee_absence_counter.create',

  EMPLOYEE_ABSENCE_COUNTER_DELETE = 'employee_absence_counter.delete',
  EMPLOYEE_ABSENCE_COUNTER_READ = 'employee_absence_counter.read',
  EMPLOYEE_ABSENCE_COUNTER_UPDATE = 'employee_absence_counter.update',

  // Employee absence
  EMPLOYEE_ABSENCE_STRIKE = 'employee_absence.strike',
  EMPLOYEE_CONTRACT_CANCEL_ACQUISITION = 'employee_contract.cancel_acquisition',
  // Employee absence request
  EMPLOYEE_ABSENCE_REQUEST_READ = 'employee_absence_request.read',
  EMPLOYEE_ABSENCE_REQUEST_UPDATE = 'employee_absence_request.update',

  // Contract
  EMPLOYEE_CONTRACT_CREATE = 'employee_contract.create',
  EMPLOYEE_CONTRACT_DELETE = 'employee_contract.delete',
  // Employee Contract In Past
  EMPLOYEE_CONTRACT_IN_PAST_CREATE = 'employee_contract.in_past_create',

  EMPLOYEE_CONTRACT_READ = 'employee_contract.read',

  EMPLOYEE_CONTRACT_UPDATE = 'employee_contract.update',
  // Employee
  EMPLOYEE_CREATE = 'employee.create',
  EMPLOYEE_DELETE = 'employee.delete',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_DELETE = 'employee_economic_unemployment.delete',

  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_IN_PAST_WRITE = 'employee_economic_unemployment.in_past_write',
  // employee economic unemployment
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_READ = 'employee_economic_unemployment.read',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_UPDATE = 'employee_economic_unemployment.update',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT_WRITE = 'employee_economic_unemployment.write',

  EMPLOYEE_FLEET_LEASING_DELETE = 'employee_fleet_leasing.delete',
  // employee fleet
  EMPLOYEE_FLEET_LEASING_UPDATE = 'employee_fleet_leasing.update',
  // EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER
  EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER_CREATE = 'employee_paid_leave_absence_counter.create',
  EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER_DELETE = 'employee_paid_leave_absence_counter.delete',
  EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER_READ = 'employee_paid_leave_absence_counter.read',

  EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER_UPDATE = 'employee_paid_leave_absence_counter.update',
  EMPLOYEE_READ = 'employee.read',

  EMPLOYEE_UPDATE = 'employee.update',
  FAQ_DELETE = 'faq.delete',
  // FAQ
  FAQ_READ = 'faq.read',
  FAQ_UPDATE = 'faq.update',

  FAQ_WRITE = 'faq.write',
  // Files
  FILE_DELETE = 'file.delete',
  // Holiday
  HOLIDAY_CREATE = 'holiday.create',
  HOLIDAY_DELETE = 'holiday.delete',

  HOLIDAY_READ = 'holiday.read',
  HOLIDAY_UPDATE = 'holiday.update',
  INFO_BUBBLES_DELETE = 'info_bubbles.delete',
  //  Info Bubbles
  INFO_BUBBLES_READ = 'info_bubbles.read',

  INFO_BUBBLES_UPDATE = 'info_bubbles.update',
  INFO_BUBBLES_WRITE = 'info_bubbles.write',
  KPI_READ = 'kpi.read',
  // Logbooks
  LOGBOOK_CREATE = 'logbook.create',

  LOGBOOK_READ = 'logbook.read',
  // Mail tokens
  MAIL_TOKEN_CREATE = 'mail_token.create',
  // Matching center
  MATCHING_CENTER_CREATE = 'matching_center.create',
  MATCHING_CENTER_DELETE = 'matching_center.delete',

  MATCHING_CENTER_READ = 'matching_center.read',
  MATCHING_CENTER_UPDATE = 'matching_center.update',
  // News
  NEWS_CREATE = 'news.create',
  NEWS_DELETE = 'news.delete',

  NEWS_READ = 'news.read',
  NEWS_UPDATE = 'news.update',
  // Office
  OFFICE_CREATE = 'office.create',
  OFFICE_DELETE = 'office.delete',

  OFFICE_READ = 'office.read',
  OFFICE_UPDATE = 'office.update',
  // Origin Company
  ORIGIN_COMPANY_CREATE = 'origin_company.create',
  ORIGIN_COMPANY_DELETE = 'origin_company.delete',

  ORIGIN_COMPANY_READ = 'origin_company.read',
  ORIGIN_COMPANY_UPDATE = 'origin_company.update',
  // Payroll
  PAYROLL_CREATE = 'payroll.create',
  PAYROLL_DELETE = 'payroll.delete',

  PAYROLL_READ = 'payroll.read',
  PAYROLL_UPDATE = 'payroll.update',
  // PERFORMANCE_DELETE = 'performance.delete',
  PERFORMANCE_FUND_MANUALLY = 'performance.fund_manually',
  PERFORMANCE_UPDATE_HOURS_EXEMPTED_FROM_INVOICING = 'performance.update_hours_exempted_from_invoicing',

  // Permission
  PERMISSION_CREATE = 'permission.create',
  PERMISSION_DELETE = 'permission.delete',
  PERMISSION_READ = 'permission.read',
  PERMISSION_UPDATE = 'permission.update',

  // Planning
  PLANNING_CREATE = 'planning.create',
  PLANNING_DELETE = 'planning.delete',
  PLANNING_READ = 'planning.read',
  PLANNING_READ_PAST = 'planning.read_past',

  PLANNING_UPDATE = 'planning.update',
  PLANNING_UPDATE_PAST_SALARY_PERIOD = 'planning.update_past_salary_period',
  READ_ONLY = 'read_only',
  // Referral premium
  REFERRAL_PREMIUM_CREATE = 'referral_premium.create',

  REFERRAL_PREMIUM_DELETE = 'referral_premium.delete',
  REFERRAL_PREMIUM_READ = 'referral_premium.read',
  REFERRAL_PREMIUM_TO_ONGOING = 'referral_premium.to_ongoing',
  REFERRAL_PREMIUM_UPDATE = 'referral_premium.update',

  // Remittances
  REMITTANCE_READ = 'remittance.read',
  REMITTANCE_SUBMIT = 'remittance.submit',
  REMITTANCE_UPDATE = 'remittance.update',
  ROLES_DELETE = 'roles.delete',

  // Roles
  ROLES_READ = 'roles.read',
  ROLES_UPDATE = 'roles.update',
  ROLES_WRITE = 'roles.write',
  SALARY_READ = 'salary.read',

  SALARY_UPDATE = 'salary.update',
  SETTINGS_DELETE = 'settings.delete',
  // Settings
  SETTINGS_READ = 'settings.read',
  SETTINGS_UPDATE = 'settings.update',

  SETTINGS_WRITE = 'settings.write',
  // Staff
  STAFF_CREATE = 'staff.create',
  STAFF_DELETE = 'staff.delete',
  STAFF_READ = 'staff.read',

  STAFF_UPDATE = 'staff.update',
  // Subscriptions
  SUBSCRIPTION_READ = 'subscription.read',
  SUBSCRIPTION_SETTINGS_CREATE = 'subscription_settings.create',
  SUBSCRIPTION_SETTINGS_DELETE = 'subscription_settings.delete',
  // Subscriptions settings
  SUBSCRIPTION_SETTINGS_READ = 'subscription_settings.read',

  SUBSCRIPTION_SETTINGS_UPDATE = 'subscription_settings.update',

  // Subsidy
  SUBSIDY_CREATE = 'subsidy.create',
  SUBSIDY_DELETE = 'subsidy.delete',
  SUBSIDY_READ = 'subsidy.read',
  SUBSIDY_UPDATE = 'subsidy.update',

  // Timetables
  TIMETABLE_CREATE = 'timetable.create',
  TIMETABLE_DELETE = 'timetable.delete',
  TIMETABLE_READ = 'timetable.read',

  TIMETABLE_UPDATE = 'timetable.update',
  // todos
  TODO_CREATE = 'todo.create',
  TODO_DELETE = 'todo.delete',
  TODO_READ = 'todo.read',

  // KPI

  TODO_UPDATE = 'todo.update',
}

export enum PermissionGroup {
  ABSENCE_CODE = 'absence_code',
  ACCIDENT = 'accident',
  ADMIN = 'admin',
  ADVANCE_AMOUNT = 'advance_amount',
  ANOMALY = 'anomaly',
  AVAILABILITY = 'availability',
  BAREMA = 'barema',
  BENEFIT_CODE = 'benefit_code',
  BLOG = 'blog',
  CHAT = 'chat',
  CHEQUE = 'cheque',
  CLAIM = 'claim',
  CUSTOMER = 'customer',
  DEBTOR = 'debtor',
  DEPARTMENTS = 'departments',
  DIMONA = 'dimona',
  DIMONA_DECLARATION = 'dimona_declaration',
  DIMONA_LOGS = 'dimona_logs',
  DISTRICT = 'district',
  ECONOMIC_UNEMPLOYMENT_DECLARATIONS = 'economic_unemployment_declarations',
  ECONOMIC_UNEMPLOYMENT_PERIODS = 'economic_unemployment_periods',
  ECONOMIC_UNEMPLOYMENT_VALIDATION_BOOK = 'economic_unemployment_validation_book',
  EMPLOYEE = 'employee',
  EMPLOYEE_ABSENCE = 'employee_absence',
  EMPLOYEE_ABSENCE_COUNTER = 'employee_absence_counter',
  EMPLOYEE_ABSENCE_REQUEST = 'employee_absence_request',
  EMPLOYEE_CONTRACT = 'employee_contract',
  EMPLOYEE_ECONOMIC_UNEMPLOYMENT = 'employee_economic_unemployment',
  EMPLOYEE_FLEET_LEASING = 'employee_fleet_leasing',
  EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER = 'employee_paid_leave_absence_counter',
  FAQ = 'faq',
  FILE = 'file',
  HOLIDAY = 'holiday',
  INFO_BUBBLES = 'info_bubbles',
  KPI = 'kpi',
  LOGBOOK = 'logbook',
  MAIL_TOKEN = 'mail_token',
  MATCHING_CENTER = 'matching_center',
  NEWS = 'news',
  OFFICE = 'office',
  ORIGIN_COMPANY = 'origin_company',
  PAYROLL = 'payroll',
  PERFORMANCE = 'performance',
  PERMISSION = 'permission',
  PLANNING = 'planning',
  READ_ONLY = 'read_only',
  REFERRAL_PREMIUM = 'referral_premium',
  REMITTANCE = 'remittance',
  ROLES = 'roles',
  SALARY = 'salary',
  SETTINGS = 'settings',
  STAFF = 'staff',
  SUBSCRIPTION = 'subscription',
  SUBSCRIPTION_SETTINGS = 'subscription_settings',
  SUBSIDY = 'subsidy',
  TIMETABLE = 'timetable',
  TODO = 'todo',
}

export const PERMISSION_GROUP_OPTIONS = computed<DataItem<PermissionGroup>[]>(() => {
  const { t } = i18nPlugin.global

  return [
    {
      label: t('permission.admin'),
      value: PermissionGroup.ADMIN,
    },
    {
      label: t('permission.read_only'),
      value: PermissionGroup.READ_ONLY,
    },
    {
      label: t('permission.blog'),
      value: PermissionGroup.BLOG,
    },
    {
      label: t('permission.departments'),
      value: PermissionGroup.DEPARTMENTS,
    },
    {
      label: t('permission.availability'),
      value: PermissionGroup.AVAILABILITY,
    },
    {
      label: t('permission.cheque'),
      value: PermissionGroup.CHEQUE,
    },
    {
      label: t('permission.employee_contract'),
      value: PermissionGroup.EMPLOYEE_CONTRACT,
    },
    {
      label: t('permission.customer'),
      value: PermissionGroup.CUSTOMER,
    },
    {
      label: t('permission.debtor'),
      value: PermissionGroup.DEBTOR,
    },
    {
      label: t('permission.employee_absence'),
      value: PermissionGroup.EMPLOYEE_ABSENCE,
    },
    {
      label: t('permission.employee'),
      value: PermissionGroup.EMPLOYEE,
    },
    {
      label: t('permission.employee_fleet_leasing'),
      value: PermissionGroup.EMPLOYEE_FLEET_LEASING,
    },
    {
      label: t('permission.employee_absence_counter'),
      value: PermissionGroup.EMPLOYEE_ABSENCE_COUNTER,
    },
    {
      label: t('permission.employee_absence_request'),
      value: PermissionGroup.EMPLOYEE_ABSENCE_REQUEST,
    },
    {
      label: t('permission.employee_paid_leave_absence_counter'),
      value: PermissionGroup.EMPLOYEE_PAID_LEAVE_ABSENCE_COUNTER,
    },
    {
      label: t('permission.news'),
      value: PermissionGroup.NEWS,
    },
    {
      label: t('permission.performance'),
      value: PermissionGroup.PERFORMANCE,
    },
    {
      label: t('permission.planning'),
      value: PermissionGroup.PLANNING,
    },
    {
      label: t('permission.timetable'),
      value: PermissionGroup.TIMETABLE,
    },
    {
      label: t('permission.todo'),
      value: PermissionGroup.TODO,
    },
    {
      label: t('permission.mail_token'),
      value: PermissionGroup.MAIL_TOKEN,
    },
    {
      label: t('permission.dimona'),
      value: PermissionGroup.DIMONA,
    },
    {
      label: t('permission.dimona_logs'),
      value: PermissionGroup.DIMONA_LOGS,
    },
    {
      label: t('permission.dimona_declaration'),
      value: PermissionGroup.DIMONA_DECLARATION,
    },
    {
      label: t('permission.accident'),
      value: PermissionGroup.ACCIDENT,
    },
    {
      label: t('permission.logbook'),
      value: PermissionGroup.LOGBOOK,
    },
    {
      label: t('permission.claim'),
      value: PermissionGroup.CLAIM,
    },
    {
      label: t('permission.file'),
      value: PermissionGroup.FILE,
    },
    {
      label: t('permission.kpi'),
      value: PermissionGroup.KPI,
    },
    {
      label: t('permission.salary'),
      value: PermissionGroup.SALARY,
    },
    {
      label: t('permission.remittance'),
      value: PermissionGroup.REMITTANCE,
    },
    {
      label: t('permission.chat'),
      value: PermissionGroup.CHAT,
    },
    {
      label: t('permission.anomaly'),
      value: PermissionGroup.ANOMALY,
    },
    {
      label: t('permission.subscription'),
      value: PermissionGroup.SUBSCRIPTION,
    },
    {
      label: t('permission.settings'),
      value: PermissionGroup.SETTINGS,
    },
    {
      label: t('permission.economic_unemployment_periods'),
      value: PermissionGroup.ECONOMIC_UNEMPLOYMENT_PERIODS,
    },
    {
      label: t('permission.employee_economic_unemployment'),
      value: PermissionGroup.EMPLOYEE_ECONOMIC_UNEMPLOYMENT,
    },
    {
      label: t('permission.economic_unemployment_declarations'),
      value: PermissionGroup.ECONOMIC_UNEMPLOYMENT_DECLARATIONS,
    },
    {
      label: t('permission.economic_unemployment_validation_book'),
      value: PermissionGroup.ECONOMIC_UNEMPLOYMENT_VALIDATION_BOOK,
    },
    {
      label: t('permission.info_bubbles'),
      value: PermissionGroup.INFO_BUBBLES,
    },
    {
      label: t('permission.faq'),
      value: PermissionGroup.FAQ,
    },
    {
      label: t('permission.roles'),
      value: PermissionGroup.ROLES,
    },
    {
      label: t('permission.advance_amount'),
      value: PermissionGroup.ADVANCE_AMOUNT,
    },
    {
      label: t('permission.barema'),
      value: PermissionGroup.BAREMA,
    },
    {
      label: t('permission.holiday'),
      value: PermissionGroup.HOLIDAY,
    },
    {
      label: t('permission.permission'),
      value: PermissionGroup.PERMISSION,
    },
    {
      label: t('permission.staff'),
      value: PermissionGroup.STAFF,
    },
    {
      label: t('permission.payroll'),
      value: PermissionGroup.PAYROLL,
    },
    {
      label: t('permission.office'),
      value: PermissionGroup.OFFICE,
    },
    {
      label: t('permission.district'),
      value: PermissionGroup.DISTRICT,
    },
    {
      label: t('permission.benefit_code'),
      value: PermissionGroup.BENEFIT_CODE,
    },
    {
      label: t('permission.absence_code'),
      value: PermissionGroup.ABSENCE_CODE,
    },
    {
      label: t('permission.subsidy'),
      value: PermissionGroup.SUBSIDY,
    },
    {
      label: t('permission.referral_premium'),
      value: PermissionGroup.REFERRAL_PREMIUM,
    },
    {
      label: t('permission.matching_center'),
      value: PermissionGroup.MATCHING_CENTER,
    },
    {
      label: t('permission.subscription'),
      value: PermissionGroup.SUBSCRIPTION,
    },
    {
      label: t('permission.subscription_settings'),
      value: PermissionGroup.SUBSCRIPTION_SETTINGS,
    },
    {
      label: t('permission.origin_company'),
      value: PermissionGroup.ORIGIN_COMPANY,
    },
  ]
})

export function getPermissionGroupLabel(group: string | PermissionGroup): string | null {
  return PERMISSION_GROUP_OPTIONS.value.find((option) => option.value === group)?.label || null
}

export enum PermissionAction {
  CANCEL_ACQUISITION = 'cancel_acquisition',
  CLOSE = 'close',
  CREATE = 'create',
  DELETE = 'delete',
  DELETE_CONCEPT = 'delete_concept',
  DELETE_FIRST_OF_MONTH = 'delete_first_of_month',
  DELETE_PAST_CANCEL_DATE = 'delete_past_cancel_date',
  FUND_MANUALLY = 'fund_manually',
  IGNORE_MAX_PERIOD_IN_TEMPORARY_CONTRACT = 'ignore_max_period_in_temporary_contract',
  IN_PAST_CREATE = 'in_past_create',
  IN_PAST_WRITE = 'in_past_write',
  READ = 'read',
  READ_ALL = 'read_all',
  READ_PAST = 'read_past',
  REFERENCE_TAKEOVER = 'reference_takeover',
  RESOLVE = 'resolve',
  RETRY_UPLOAD = 'retry_upload',
  SET_CUSTOM_INVOICE_PRICE = 'set_custom_invoice_price',
  SETTINGS = 'settings',
  STRIKE = 'strike',
  SUBMIT = 'submit',
  TO_ONGOING = 'to_ongoing',
  UPDATE = 'update',
  UPDATE_CONCEPT = 'update_concept',
  UPDATE_HOURS_EXEMPTED_FROM_INVOICING = 'update_hours_exempted_from_invoicing',
  UPDATE_PAST_SALARY_PERIOD = 'update_past_salary_period',
  UPDATE_STATUS = 'update_status',
  VALIDATE = 'validate',
  WRITE = 'write',
}

export const PERMISSION_ACTION_OPTIONS = computed<DataItem<PermissionAction>[]>(() => {
  const { t } = i18nPlugin.global

  return [
    {
      label: t('permission.action.create'),
      value: PermissionAction.CREATE,
    },
    {
      label: t('permission.action.read'),
      value: PermissionAction.READ,
    },
    {
      label: t('permission.action.update'),
      value: PermissionAction.UPDATE,
    },
    {
      label: t('permission.action.delete'),
      value: PermissionAction.DELETE,
    },
    {
      label: t('permission.action.write'),
      value: PermissionAction.WRITE,
    },
    {
      label: t('permission.action.strike'),
      value: PermissionAction.STRIKE,
    },
    {
      label: t('permission.action.settings'),
      value: PermissionAction.SETTINGS,
    },
    {
      label: t('permission.action.in_past_create'),
      value: PermissionAction.IN_PAST_CREATE,
    },
    {
      label: t('permission.action.in_past_write'),
      value: PermissionAction.IN_PAST_WRITE,
    },
    {
      label: t('permission.action.set_custom_invoice_price'),
      value: PermissionAction.SET_CUSTOM_INVOICE_PRICE,
    },
    {
      label: t('permission.action.reference_takeover'),
      value: PermissionAction.REFERENCE_TAKEOVER,
    },
    {
      label: t('permission.action.close'),
      value: PermissionAction.CLOSE,
    },
    {
      label: t('permission.action.fund_manually'),
      value: PermissionAction.FUND_MANUALLY,
    },
    {
      label: t('permission.action.update_past_salary_period'),
      value: PermissionAction.UPDATE_PAST_SALARY_PERIOD,
    },
    {
      label: t('permission.action.retry_upload'),
      value: PermissionAction.RETRY_UPLOAD,
    },
    {
      label: t('permission.action.update_status'),
      value: PermissionAction.UPDATE_STATUS,
    },
    {
      label: t('permission.action.update_concept'),
      value: PermissionAction.UPDATE_CONCEPT,
    },
    {
      label: t('shared.hours_exempt_from_invoicing'),
      value: PermissionAction.UPDATE_HOURS_EXEMPTED_FROM_INVOICING,
    },
    {
      label: t('permission.action.delete_concept'),
      value: PermissionAction.DELETE_CONCEPT,
    },
    {
      label: t('permission.action.submit'),
      value: PermissionAction.SUBMIT,
    },
    {
      label: t('permission.action.validate'),
      value: PermissionAction.VALIDATE,
    },
    {
      label: t('permission.action.resolve'),
      value: PermissionAction.RESOLVE,
    },
    {
      label: t('permission.action.read_all'),
      value: PermissionAction.READ_ALL,
    },
    {
      label: t('permission.action.delete_past_cancel_date'),
      value: PermissionAction.DELETE_PAST_CANCEL_DATE,
    },
    {
      label: t('permission.action.delete_first_of_month'),
      value: PermissionAction.DELETE_FIRST_OF_MONTH,
    },
    {
      label: t('permission.action.read_past'),
      value: PermissionAction.READ_PAST,
    },
    {
      label: t('permission.action.to_ongoing'),
      value: PermissionAction.TO_ONGOING,
    },
    {
      label: t('permission.action.ignore_max_period_in_temporary_contract'),
      value: PermissionAction.IGNORE_MAX_PERIOD_IN_TEMPORARY_CONTRACT,
    },
    {
      label: t('permission.cancel_acquisition'),
      value: PermissionAction.CANCEL_ACQUISITION,
    },
  ]
})

export function getPermissionActionLabel(action: string | PermissionAction): string | null {
  return PERMISSION_ACTION_OPTIONS.value.find((option) => option.value === action)?.label || null
}
