/* eslint-disable no-console */
export function logError(...error: unknown[]): void {
  console.error(error)
}

export function logInfo(...message: unknown[]): void {
  console.log(message)
}

export function logDebug(...message: unknown[]): void {
  console.debug(message)
}
